import * as React from 'react'
import { getMessaging, deleteToken, isSupported } from 'firebase/messaging'

import { IS_PRODUCTION } from '@/constants/env'

export default function useDeleteFirebaseCloudMessagingToken() {
  return React.useCallback(async () => {
    // IS_PRODUCTION: 개발환경에서 에러나는 현상 대응
    if ((await isSupported()) && IS_PRODUCTION) {
      const messaging = getMessaging()
      deleteToken(messaging)
    }
  }, [])
}
