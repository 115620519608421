import { useTranslation, TFunction } from 'react-i18next'

import { LocaleEnum } from '@/types/locale'

const useLanguage = (
  ns?: string,
): { t: TFunction<string>; language: LocaleEnum; isKorean: boolean } => {
  const { t, i18n } = useTranslation(ns)
  const language = i18n.language as LocaleEnum
  const isKorean = language === LocaleEnum.ko
  return { t, language, isKorean }
}

export default useLanguage
