// worker 번들러랑 코드 공유하는데 worker 번들러에 @ resovler가 사용설정되어 있지 않아서 import 경로에 '@' 사용 못함에 유의
import { IS_PRODUCTION_OPERATION } from './env'

export const firebaseConfig = IS_PRODUCTION_OPERATION
  ? {
      apiKey: 'AIzaSyA47-Q_Q_qFtCveD-xdfDJnn_Y8uW2Eln0',
      authDomain: 'therich-io-dev.firebaseapp.com',
      databaseURL: 'https://therich-io-dev.firebaseio.com',
      projectId: 'therich-io-dev',
      storageBucket: 'therich-io-dev.appspot.com',
      messagingSenderId: '1517924880',
      appId: '1:1517924880:web:98ff79c9cc2cafe1619095',
      measurementId: 'G-S2JPD7PL80',
    }
  : {
      apiKey: 'AIzaSyAUEDXVquxkj1Ybe6F3pQh-flDk1hH9pkw',
      authDomain: 'therich-chat.firebaseapp.com',
      databaseURL: 'https://therich-chat.firebaseio.com',
      projectId: 'therich-chat',
      storageBucket: 'therich-chat.appspot.com',
      messagingSenderId: '826593673837',
      appId: '1:826593673837:web:97b83b704eed8fc4983564',
    }
