import * as React from 'react'
import LogRocket from 'logrocket'

import useIsExcludePaths from '@/utils/browser/exclude-paths-hook'

type Props = {
  userId?: string
  nickName?: string
}

const excludePaths = ['/mbti-test', '/verification/email']

const useLogrocket = ({ userId, nickName }: Props): void => {
  const isExcludePaths = useIsExcludePaths({ paths: excludePaths })

  React.useEffect(() => {
    if (process.env.NEXT_PUBLIC_LOGROCKET_APP_ID && !isExcludePaths) {
      LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID)
      if (userId && nickName) {
        LogRocket.identify(userId, { name: nickName, nickName })
      }
    }
  }, [nickName, userId, isExcludePaths])
}

export default useLogrocket
