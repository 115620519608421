/**
 * FCM 알림 클릭시 시나리오
 *
 * 1. 포그라운드 상황(firebase-cloud-messaging-hook)
 * 웹앱에 정의된(메인 쓰레드에서 동작하는 코드) onMessage 함수로 핸들링
 * router.push
 *
 * 2. 백그라운드 상황(firebase-cloud-messaging-sw)
 * worker.ts에 정의된 onBackgroundMessage FCM API를 사용하여 서비스워커에서 웹앱으로 메시지 전송 후 웹앱에서 핸들링
 * router.push
 */

export { default as initializeFirebase } from './initialize'
export { default as useFirebaseCloudMessaging } from './firebase-cloud-messaging-hook'
export { default as getFirestoreDB } from './firestore'
export { default as useDeleteFirebaseCloudMessagingToken } from './firebase-cloud-messaging-delete-token-hook'
