import React from 'react'
import { useRouter } from 'next/router'

import { getAuthTokenNickname, getAuthTokenUserId } from '@/service/auth'

import ChannelService from './channel-talk'

const hidePaths = [
  '/community',
  '/the-rich-game',
  '/mbti-test',
  '/ie-guide',
  '/events/',
  '/portfolio-generator',
  '/mailing-group',
  '/trading-fee-calculator',
  '/loan-interest-calculator',
  '/risk-tolerance-assessment',
  '/professional-investor-badge',
  '/professional-investor-application',
  '/badge-system',
  '/ebest',
]

export default function useChannelTalk(): void {
  const { asPath } = useRouter()
  const channelServiceRef = React.useRef<ChannelService | null>(null)
  const channelBoot = React.useCallback(() => {
    if (!channelServiceRef.current) {
      channelServiceRef.current = new ChannelService()
    }
    const userId = getAuthTokenUserId()
    const option = userId
      ? {
          memberId: userId,
          profile: {
            name: getAuthTokenNickname(),
          },
        }
      : {}

    channelServiceRef.current.boot({
      pluginKey: '84b8a5eb-6093-4f42-873b-5bcc5725843c',
      ...option,
    })
  }, [])

  React.useEffect(() => {
    channelBoot()
  }, [channelBoot])

  React.useEffect(() => {
    if (hidePaths.find((path) => asPath.startsWith(path))) {
      ChannelService.hideChannelButton()
    } else {
      ChannelService.showChannelButton()
    }
  }, [asPath, channelBoot])
}
