import {
  Firestore,
  getFirestore,
  doc,
  onSnapshot,
  collection,
  query,
  orderBy,
  limit,
  where,
  getDocs,
  getDoc,
} from 'firebase/firestore'

import type { Locale } from '@/types/locale'
import type { Chat, PublicChatRoom, MyChatRoom, ChatRoom } from '@/types/chat-room'
import type { ChatMessage } from '@/types/chat-message'
import type { ChatNotification } from '@/types/chat-notification'
import sendErrorLog from '@/service/error-log'
import { ChatSystemPublicChatUser, ChatUsers } from '@/types/chat-user'

import { signInWithCustomToken } from './firebase-auth'

let firestoreDB: Firestore | null = null

export default function getFirestoreDB() {
  if (firestoreDB === null) {
    firestoreDB = getFirestore()
  }
  return firestoreDB
}

const COL = {
  CHAT_SYSTEM: 'chat-system',
  USERS: 'users', // 여기에는 비공개, 주주모임 방만 있고 공개방은 없음
  CHATS: 'chats',
  CHAT_USERS: 'chat-users',
  MESSAGES: 'messages',
  CHAT_NOTIFICATIONS: 'chat-notifications',
} as const

const DOC = {
  [COL.CHAT_SYSTEM]: {
    PUBLIC_CHAT_USERS: 'public-chat-users',
    PUBLIC_CHATS: 'public-chats',
  },
} as const

type OnError = (message: Locale) => void
function responseHandler<Data>({
  onSuccess,
  onError,
  exists,
  getData,
  errorMessage,
}: {
  onSuccess: (data: Data) => void
  onError: OnError
  exists: boolean
  getData: () => Data
  errorMessage: Locale
}) {
  if (exists) {
    onSuccess(getData())
  } else {
    sendErrorLog(new Error(errorMessage.en))
    onError(errorMessage)
  }
}

export function subscribePublicChats(
  onSuccess: (publicChatRooms: PublicChatRoom[]) => void,
  onError: OnError,
) {
  return onSnapshot(
    doc(getFirestoreDB(), COL.CHAT_SYSTEM, DOC[COL.CHAT_SYSTEM].PUBLIC_CHATS),
    (doc) => {
      responseHandler<PublicChatRoom[]>({
        onSuccess,
        onError,
        exists: doc.exists(),
        getData: () => doc.get('publicChats'),
        errorMessage: { en: 'publicChats not exists', ko: '존재하지 않는 공개 채팅방' },
      })
    },
    sendErrorLog,
  )
}

// chat id를 기반으로 하는 참여한 유저정보를 모두 가져옴(collection 기반이 아니라 쿼리 못함)
export function subscribePublicChatsUsers(
  callback: (publicChatUsers: ChatSystemPublicChatUser[]) => void,
) {
  return onSnapshot(
    doc(getFirestoreDB(), COL.CHAT_SYSTEM, DOC[COL.CHAT_SYSTEM].PUBLIC_CHAT_USERS),
    (doc) => {
      if (doc.exists()) {
        callback(doc.get('publicChatUsers'))
      } else {
        sendErrorLog(new Error('publicChatUsers not exists'))
      }
    },
    sendErrorLog,
  )
}

// 비공개 채팅방(주주 모임방 포함)에 한해서 입장된 채팅방(공개는 subscribePublicChats 에서 구독)
export function subscribeMyChats(callback: (myChats: MyChatRoom[]) => void, userId: string) {
  return onSnapshot(
    doc(getFirestoreDB(), COL.USERS, userId),
    (doc) => {
      if (doc.exists()) {
        callback(doc.get('myChats'))
      } else {
        // 실제로 없을 수 있음
        // sendErrorLog(new Error('myChatRooms not exists'))
      }
    },
    sendErrorLog,
  )
}

export function subscribeChatMessage(
  callback: (messages: ChatMessage[]) => void,
  chatId: string,
  fetchLimit: number,
) {
  try {
    const messageRef = collection(getFirestoreDB(), COL.CHATS, chatId, COL.MESSAGES)
    return onSnapshot(
      query(messageRef, orderBy('createdAt', 'desc'), limit(fetchLimit)),
      (querySnapshot) => {
        const messages: ChatMessage[] = []
        querySnapshot.docChanges().forEach((change) => {
          switch (change.type) {
            case 'added':
            case 'modified':
              if (change.doc.exists()) {
                const message = change.doc.data()
                message['id'] = change.doc.id
                message['changeType'] = change.type
                messages.push(message as ChatMessage)
              }
              break
          }
        })

        callback(messages)
      },
    )
  } catch (error) {
    sendErrorLog(error as Error)
    const fallbackFunc = () => null
    return fallbackFunc
  }
}

export function subscribeChat(
  onSuccess: (chatRoom: ChatRoom) => void,
  onError: OnError,
  chatId: string,
) {
  try {
    const chatRef = doc(getFirestoreDB(), COL.CHATS, chatId)
    return onSnapshot(chatRef, (doc) => {
      responseHandler<ChatRoom>({
        onSuccess,
        onError,
        exists: doc.exists(),
        getData: () => {
          const chat = doc.data() as Chat
          if (chat.roomType === 'stock' || chat.roomType === 'crypto') {
            // 자산방은 chat id 필드가 없음..
            return { ...chat, id: chatId } as ChatRoom
          }
          return chat as ChatRoom
        },
        errorMessage: {
          en: `chatRoom not exists: chatId(${chatId}) `,
          ko: `존재하지 않는 공개 채팅방: chatId(${chatId})`,
        },
      })
    })
  } catch (error) {
    sendErrorLog(error as Error)
    const fallbackFunc = () => null
    return fallbackFunc
  }
}

export function subscribeChatUsers(callback: (chatUser: ChatUsers) => void, chatId: string) {
  try {
    const chatUsersRef = doc(getFirestoreDB(), COL.CHAT_USERS, chatId)
    return onSnapshot(
      chatUsersRef,
      (doc) => {
        if (doc.exists()) {
          callback(doc.data() as ChatUsers)
        } else {
          sendErrorLog(new Error(`chatId(${chatId}) chatUser not exists`))
        }
      },
      sendErrorLog,
    )
  } catch (error) {
    sendErrorLog(error as Error)
    const fallbackFunc = () => null
    return fallbackFunc
  }
}

export function subscribeChatNotification(
  callback: (notification: ChatNotification) => void,
  chatId: string,
) {
  const chatNotificationRef = doc(getFirestoreDB(), COL['CHAT_NOTIFICATIONS'], chatId)
  return onSnapshot(
    chatNotificationRef,
    (doc) => {
      if (doc.exists()) {
        callback(doc.data() as ChatNotification)
      }
    },
    sendErrorLog,
  )
}

export async function queryPrevChatMessages({
  chatId,
  messageCreatedAt,
  fetchLimit,
}: {
  chatId: string
  messageCreatedAt: ChatMessage['createdAt']
  fetchLimit: number
}) {
  const chatUsersRef = collection(getFirestoreDB(), COL.CHATS, chatId, COL.MESSAGES)
  const q = query(
    chatUsersRef,
    where('createdAt', '<', messageCreatedAt),
    orderBy('createdAt', 'desc'),
    limit(fetchLimit),
  )
  const querySnapshot = await getDocs(q)
  const messages: ChatMessage[] = []
  if (!querySnapshot.empty) {
    querySnapshot.forEach((doc) => {
      const message = doc.data()
      message['id'] = doc.id
      messages.push(message as ChatMessage)
    })
  }
  return messages
}

export async function queryChat({ chatId }: { chatId: string }) {
  try {
    await signInWithCustomToken() // firestore rule에 의존하는 로직
    const chatRef = doc(getFirestore(), COL.CHATS, chatId)
    const chatSnapshot = await getDoc(chatRef)
    if (chatSnapshot.exists()) {
      const chat = chatSnapshot.data() as Chat
      if (chat.roomType === 'stock') {
        // 주식방은 chat id 필드가 없음..
        return { ...chat, id: chatId } as ChatRoom
      }
      return chat as ChatRoom
    }
  } catch (error) {
    sendErrorLog(error as Error)
    return null
  }
}

// 공개방인 경우는 사용 못함
export async function queryMyChat({ chatId, userId }: { chatId: string; userId: string }) {
  const myChatRef = collection(getFirestoreDB(), COL.USERS, userId, COL.CHATS)
  const q = query(myChatRef, where('id', '==', chatId), limit(1))
  const querySnapshot = await getDocs(q)
  const chatRoom: MyChatRoom[] = []
  if (!querySnapshot.empty) {
    querySnapshot.forEach((doc) => {
      chatRoom.push(doc.data() as MyChatRoom)
    })
    return chatRoom[0]
  }
  return null
}

export async function queryChatUsers({ chatId }: { chatId: string }) {
  try {
    const chatUsersRef = doc(getFirestoreDB(), COL.CHAT_USERS, chatId)
    const chatUsersSnapshot = await getDoc(chatUsersRef)
    if (chatUsersSnapshot.exists()) {
      return chatUsersSnapshot.data() as ChatUsers
    }
    return null
  } catch (error) {
    sendErrorLog(error as Error)
    return null
  }
}

export async function queryChatNotifications({ chatId }: { chatId: string }) {
  const chatNotificationRef = doc(getFirestoreDB(), COL['CHAT_NOTIFICATIONS'], chatId)
  const chatNotificationSnapshot = await getDoc(chatNotificationRef)
  if (chatNotificationSnapshot.exists()) {
    return chatNotificationSnapshot.data() as ChatNotification
  }
  return null
}

/**
 * 파이어스토어를 이용한 채팅 서비스
 *
 * 기본적으로 채팅 정보는 파이어스토어와 GQL 혼합해서 사용한다.
 * 파이어스토어 이용 로직
 * 1. 퍼블릭 채팅방 관련
 * 2. 특정유저(로그인 유저) 채팅방 관련(public/private 모두)
 * 3. 특정 주식 채팅방 관련
 * 4. 채팅방 메시지
 *
 * 채팅방 타입은 3가지
 * 1. public(공개)
 *   - roomType === 'public'
 * 2. private(비공개)
 *   - roomType === 'private'
 * 3. stock(주주 모임)
 *   - roomType === 'stock'
 * 3. crypto(주주 모임)
 *   - roomType === 'crypto'
 *
 * UI 노출 채팅방 타입 3가지
 * 1. 참여한 채팅방
 *   - 참여한 공개 및 비공개 채팅방
 * 2. 추천 채팅방
 *   - 공개 중 참여한지 않은 채팅방
 * 3. 주주 모임
 *   - 참여한 채팅방은 보여주고 특정 주식 검색 후 참여할 수 있도록 제공
 *
 * 채팅에 필요한 토큰 로직
 * 채팅서버로 authToken 사용하여 chatToken 획득
 * chatToken으로 firebase auth custom token 요청하여 idToken(chatIdToken) 획득
 * idToken 으로 채팅 권한관련 GQL 뮤테이션시 사용
 *
 */
