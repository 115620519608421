import { useRouter } from 'next/router'

type Props = {
  paths: string[]
}

// 특정 path가 현재 url path에 해당하는 path일 경우 true 반환
export default function useIsExcludePaths({ paths }: Props): boolean {
  const { asPath } = useRouter()
  return paths.some((path) => asPath.startsWith(path))
}
