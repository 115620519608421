import * as React from 'react'
import Router from 'next/router'
import { onMessage } from 'firebase/messaging'

import { useUpdateSignInSessionMutation } from '@/gql/generated-api1'
import useLanguage from '@/utils/language/language-hook'
import { useAuthTokenStore } from '@/service/auth/store'
import { isChattingPage } from '@/route/chats'

import firebaseMessageToken from './firebase-message-token'

export default function useFirebaseCloudMessaging() {
  const clientTokenRef = React.useRef('')
  const { token } = useAuthTokenStore()
  const { language } = useLanguage()
  const [updateSignInSession] = useUpdateSignInSessionMutation()

  const initialize = React.useCallback(async () => {
    if (clientTokenRef.current) return
    const { messageToken, messaging } = await firebaseMessageToken()
    if (!messageToken || !messaging) return

    clientTokenRef.current = messageToken
    // 서버에 토큰 저장 로직
    updateSignInSession({
      variables: {
        deviceRegistrationToken: messageToken,
        language,
      },
    })

    // 포그라운드일 경우 메시지 이벤트 핸들러(백그라운드인 경우에는 service worker에서 핸들링)
    onMessage(messaging, (payload) => {
      if (payload.notification && payload.notification.title) {
        const { query } = Router
        // 알림온 방에서 현재 채팅중인 경우 필터(채팅 url path에 의존함에 주의)
        const chatId = (query['chatId'] || query['chat-id']) as string
        if (isChattingPage() && chatId && payload.data?.linkUrl) {
          const isEqualChattingPage = payload.data.linkUrl.indexOf(chatId) > -1
          if (isEqualChattingPage) return // 채팅페이지에서 현재 페이지와 같은 링크의 알림인 경우 알림 노출 안함
        }

        const notification = new Notification(payload.notification.title, {
          body: payload.notification.body,
          icon: '/icon-192x192.png',
        })
        notification.onclick = function (event) {
          event.preventDefault() // prevent the browser from focusing the Notification's tab
          if (payload.data?.linkUrl) {
            notification.close()
            linkHandler({ link: payload.data.linkUrl })
          }
        }
      }
    })
  }, [language, updateSignInSession])

  React.useEffect(() => {
    if (token) {
      initialize()
    }
  }, [token, initialize])

  // 창이 열려있는 포커싱되지 않은 백그라운드 상태인 경우에는 직접 핸들링
  React.useEffect(() => {
    const messageEventHandler = (event: MessageEvent) => {
      if (event.data.type === 'FCM') {
        if (event.data.action === 'route-push' && event.data.url) {
          linkHandler({ link: event.data.url })
        }
      }
    }
    navigator.serviceWorker?.addEventListener('message', messageEventHandler)
    return () => {
      navigator.serviceWorker?.removeEventListener('message', messageEventHandler)
    }
  }, [])
}

function linkHandler({ link }: { link: string }) {
  const [, path] = link.split('#')[0].split(location.origin)
  const isEqualPage = path === location.pathname
  if (isEqualPage) {
    location.href = link
    location.reload()
  } else {
    Router.push(link)
  }
}
