import { initializeApp, getApps } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

import { firebaseConfig } from '@/constants/firebase'
import { IS_PRODUCTION_OPERATION } from '@/constants/env'

import getFirestoreDB from './firestore'

export default function initialize() {
  if (typeof global.window !== 'undefined' && !getApps().length) {
    initializeApp(firebaseConfig)
    IS_PRODUCTION_OPERATION && getAnalytics()
    getFirestoreDB()
  }
}
