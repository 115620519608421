import * as React from 'react'

type Props = {
  userId?: string
}

const useGA = ({ userId }: Props): void => {
  React.useEffect(() => {
    if (typeof window.gtag === 'function' && userId) {
      window.gtag('set', { user_id: userId })
    }
  }, [userId])
}

export default useGA
