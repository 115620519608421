import * as React from 'react'

import { getAuthTokenUserId, getAuthTokenNickname, useAuthToken } from '@/service/auth'
import { IS_PRODUCTION } from '@/constants/env'
import { initializeFirebase, useFirebaseCloudMessaging } from '@/api/firebase'

import useGA from './ga-hook'
import useLogrocket from './logrocket-hook'
import useChannelTalk from './channel-talk-hook'

type Props = {
  children: JSX.Element | JSX.Element[]
}

initializeFirebase()

function ThirdPartyOnlyProduction({ children }: { children: React.ReactNode }) {
  const { token } = useAuthToken()
  const user = React.useMemo(
    () => ({
      userId: token ? getAuthTokenUserId() ?? '' : '',
      nickName: token ? getAuthTokenNickname() ?? '' : '',
    }),
    [token],
  )

  useGA({ userId: user.userId })
  useLogrocket(user)
  useChannelTalk()

  return <>{children}</>
}

function IsNeedThirdParty({ children }: Props) {
  useFirebaseCloudMessaging()

  return (
    <>
      {IS_PRODUCTION ? (
        <ThirdPartyOnlyProduction>{children}</ThirdPartyOnlyProduction>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default React.memo(IsNeedThirdParty)
